import React from 'react';
import { useDispatch } from 'react-redux'
import Layout from "../../components/Investor/layout";
import SEO from "../../components/seo"
import styles from "../../components/Investor/news.module.css"
import { graphql,  navigate } from 'gatsby';
import { getMomentDate, isNullUndefined, transformMarkdownToReact, richTextOptions, capitalizeFirstLetter, addDisclaimersToCmsLinks} from "../../util/"
import {showInvestorDisclaimerPopup} from "../../actions/investor/prompts/disclaimers/"
import {toggleInvestorDisclaimerPopup} from "../../controllers/investor/disclaimer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultInvestorDisclaimerPopupConfigs } from '../../util/configs'
import queryString from "query-string"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"

export const pageQueryNewsItemTemplate = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulNewsItemTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                author
                description {
                    json
                }
                bodyText {
                    childMarkdownRemark {
                        html
                        htmlAst
                    }
                }
                datePublished
                slug
                title
        }
    }
`

const NewsPage = (props) => {

    const dispatch = useDispatch()

    let {author, datePublished, title, description, slug, bodyText} = props.data.contentfulNewsItemTemplate

    let date = getMomentDate(datePublished);

    let richText = documentToReactComponents(isNullUndefined(description) ? description : description.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showInvestorDisclaimerPopup(actionConfig));
    }
    React.useEffect(() => {
    }, []);
    return ( 
            
        <TemplateWrapper>
            <Layout>
        <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />
            <div className={"container"} style={{marginTop: "140px"}}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <a style={{cursor: "pointer"}} title="go back" onClick={e => {
                            e.preventDefault();
                            let {prevPath, activeYear} = props.location.state;
                            if(!isNullUndefined(prevPath) && !isNullUndefined(activeYear)){
                                navigate(`${prevPath}${prevPath.endsWith("/") ? "": "/"}?activeYear=${activeYear}`)
                            }else{
                                window.history.back()
                            }
                        }} className={styles.goBack}>
                        <span>❮</span>
                        </a> 
                        <h2 style={{marginTop: '10px', color: '#8bc63e'}}>{title}</h2>
                    </div>
                </div>

               

                <div className={"row"}>
                    <div className={"col-12"}>
                        <hr/>
                        <div className={styles.author}>
                        {/* <h5>{author}</h5>  */}
                            <span className={styles.date}>{`${date.format("Do")} ${date.format("MMMM")} ${date.format("YYYY")}`}</span>
                        </div>
                        <hr/>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                    {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}


                    {!isNullUndefined(bodyText) && !isNullUndefined(bodyText.childMarkdownRemark) && !isNullUndefined(bodyText.childMarkdownRemark.htmlAst) && transformMarkdownToReact(bodyText.childMarkdownRemark.htmlAst, showExternalLinkDisclaimer)}
                          
                   </div>
                </div>
            </div>
      </Layout>
        </TemplateWrapper>
     );
}
 
export default NewsPage;
