import React from "react"
import {isNullUndefined} from "../../util/"
import {getDefaultInvestorDisclaimerPopupConfigs} from "../../util/configs"

export function toggleInvestorDisclaimerPopup(e, urlToVisit, openPopup){
    e.preventDefault()
    if(isNullUndefined(urlToVisit)){
        return;
    }
    let okCallback = () => window.open(urlToVisit, "_blank")
    let closeCallback = () => {}
    let popupConfig = getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback);
    openPopup(popupConfig)
}